export const apiRoutes = {
  auth: {
    refreshToken: 'auth/refresh-token',
    email: {
      login: 'auth/email/login',
      registrationConfirmation: 'auth/email/registration-confirmation',
      passwordChange: 'auth/email/password-change',
      passwordResetRequest: 'auth/email/password-reset-request',
      passwordReset: 'auth/email/password-reset',
    },
    google: {
      login: 'auth/google/login',
    },
  },
  translations: {
    list: 'translations',
  },
  languages: {
    locale: 'languages/locale/:locale',
    options: 'languages/options',
  },
  users: {
    get: 'users/:id',
    me: 'users/me',
    list: 'users',
    create: 'users',
    update: 'users/:id',
    delete: 'users/:id',
    selfDeleteRequest: 'users/self-delete-request',
    selfDelete: 'users/self-delete/:token',
    bulkDelete: 'users/bulk-delete',
  },
  userTokens: {
    get: 'user-tokens/:token',
  },
  userSettings: {
    updateSelf: 'user-settings/update-self',
    getSelf: 'user-settings/get-self',
    getLayoutOptions: 'user-settings/options/layout',
  },
  profile: {
    updateSelf: 'profile/update-self',
    getSelf: 'profile/get-self',
  },
  roles: {
    options: 'roles/options',
    mappedAccess: 'roles/mapped-access',
  },
  assets: {
    uploadImage: 'assets/upload-image',
    getAsset: 'assets/owned/:id',
  },
  health: {
    version: 'health/version',
  },
  audit: {
    list: 'audit',
  },
  clients: {
    get: 'clients/:id',
    list: 'clients',
    create: 'clients',
    update: 'clients/:id',
    delete: 'clients/:id',
    options: 'clients/options',
  },
  projects: {
    get: 'projects/:id',
    list: 'projects',
    create: 'projects',
    update: 'projects/:id',
    delete: 'projects/:id',
  },
  clientRepresentatives: {
    get: 'client-representatives/:id',
    list: 'client-representatives',
    create: 'client-representatives',
    update: 'client-representatives/:id',
    delete: 'client-representatives/:id',
    rolesOptions: 'client-representatives/role-options',
    bulkDelete: 'client-representatives/bulk-delete',
  },
};
